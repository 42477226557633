import * as React from 'react';
import HomePageWrapper from './style';
import Posts from './posts';
import Banner from './banner';

type PersonalBlogProps = {};

const HomeContainer: React.FunctionComponent<PersonalBlogProps> = (props) => {
  return (
    <HomePageWrapper {...props}>
      <Banner />
      <Posts />
    </HomePageWrapper>
  );
};

export default HomeContainer;
