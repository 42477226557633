import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BlogPostsWrapper, { Title } from "./style";
import PostCardList from "../../../components/post-card-list/post-card-list";
import ImageCardList from "../../../components/image-card-list/image-card-list";

type PostsProps = {};

const Posts: React.FunctionComponent<PostsProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      posts: allMdx(sort: { fields: [frontmatter___createdDate], order: DESC }, filter: {frontmatter: {contentType: {eq: "wishes"}}}) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 100)
            frontmatter {
              createdDate(formatString: "MMM DD, YYYY")
              title
              tags
              slug
              image {
                childImageSharp {
                  fluid(maxWidth: 570, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      },
      imageCards: allMdx(sort: { fields: [frontmatter___createdDate], order: DESC }, filter: {frontmatter: {contentType: {eq: "e-cards"}}}) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 70)
            frontmatter {
              createdDate(formatString: "MMM DD, YYYY")
              title
              tags
              slug
              image {
                childImageSharp {
                  fluid(maxWidth: 570, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const Posts = Data.posts.edges;
  const ImageCards = Data.imageCards.edges;

  return (
    <BlogPostsWrapper>
      <Title>Recent Posts</Title>
      <PostCardList posts={Posts} />
      <Title>Recent Cards</Title>
      <ImageCardList posts={ImageCards} />
    </BlogPostsWrapper>
  );
};

export default Posts;
