import * as React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import {
  FeaturedPostWrapper,
  PostPreview,
  PostDetails,
  PostTitle,
  PostMeta,
  PostTags
} from './feature-post.style';
import { generateTagPagePath } from '../../utils';

interface FeaturedPostProps {
  image?: any;
  title: string;
  url: string;
  tags?: string;
  className?: string;
  imageType?: 'fixed' | 'fluid';
  placeholderBG?: string;
}

const FeaturedPost: React.FunctionComponent<FeaturedPostProps> = ({
  image,
  title,
  url,
  tags,
  className,
  imageType,
  placeholderBG,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['featured_post'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <FeaturedPostWrapper className={addAllClasses.join(' ')} {...props}>
      {image == null ? null : (
        <PostPreview className="post_preview">
          <Link to={url}>
            {imageType === 'fluid' ? (
              <Img fluid={image} alt="post preview" backgroundColor={placeholderBG} />
            ) : (
              <Img fixed={image} alt="post preview" backgroundColor={placeholderBG} />
            )}
          </Link>
        </PostPreview>
      )}

      <PostDetails>
        <PostTitle className="post_title">
          <Link to={url}>{title}</Link>
        </PostTitle>
        <PostMeta>
          {tags == null ? null : (
            <PostTags className="post_tags">
              {tags
                .split(', ')
                .map(tag => tag.trim())
                .filter(tag => tag !== 'featured')
                .slice(0, 2)
                .map((tag: string, index: number) => (
                  <Link key={index} to={generateTagPagePath(tag)}>{`#${tag}`}</Link>
                ))}
            </PostTags>
          )}
        </PostMeta>
      </PostDetails>
    </FeaturedPostWrapper>
  );
};

FeaturedPost.defaultProps = {
  imageType: 'fluid',
};

export default FeaturedPost;
